<template>
    <div class="m-wrapper03">
        <div class="setting-con01" style="justify-content: space-between">
            <span class="setting-lab01" style="width: auto;">
                张三
                <!-- <em class="myself-box">(本人)</em> -->
            </span>
            <a class="addcard-button" @click="addCard" style="width: auto;">
                <div class="m-patient-but patient-add-1">新增就诊卡</div>
            </a>
        </div>
        <ul class="m-setting01">
            <a class="card-container" v-for="(card, index) in cardList" :key="card.hospitalName" @click="watchCardDetail(card)">
                <li class="ka-box" :class="index % 2 === 0 ? 'b-green' : 'b-blue'">
                   <p>{{card.hospitalName}}</p>
                   <b>{{card.cardNumber}}</b>
                   <div class="ka-more">
                    查看
                    <i class="ka-i"></i>
                   </div>
                </li>
            </a>
        </ul>
    </div>
</template>
<script>
export default {
    data() {
        return {
            cardList: [{
                id: 1,
                hospitalName: '福建省立医院',
                cardNumber: 'A03668111'
            }, {
                id: 2,
                hospitalName: '福建人民医院',
                cardNumber: 'A03668111'
            }]
        }
    },
    methods: {
        addCard() {
            // 跳转到添加就诊卡界面
            this.$router.push('/addCard/1?from=add') 
        },
        watchCardDetail(card) {
            console.log('card,', card)
            // 查看当前就诊卡信息
            this.$router.push(`/addCard/2?from=add&no=${card.cardNumber}&name=${card.hospitalName}&id=${card.id}`)
        }
    }
}
</script>
<style lang="scss" scoped>
$mainColor: #00C2C3;
.m-wrapper03 {
    height: 100vh;
    background: #fff;

    .settting-con01 {
        display: flex;
    }

    .card-container {
        display: block;
    }
}
.ka-box {
    text-align: left;

    &.b-blue {
        background: #51CDA7 !important;
    }

    &.b-green {
        background: #5688F7 !important;
    }
}

.patient-add-1 {
    background: none;
    border: 1px solid $mainColor;
    color: $mainColor;
}
</style>